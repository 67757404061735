import React from "react";
import Namespace from 'src/constants/locale/Namespace'
import FileForUserKeys from '../../../constants/locale/key/FileForUser'
import { components, useTranslation } from 'cng-web-lib'

const {
  button: { CngButton }
} = components

function FileForUserButtonComponent({
  onSubmit,
}) {

  const { translate } = useTranslation(Namespace.FILE_FOR_USER)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {

    let submiButton = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.SUBMIT_BUTTON
    )
    return {
      submiButton
    }
  }

  return (
    <CngButton onClick={onSubmit}>
      {translatedTextsObject.submiButton}
    </CngButton>
  );
}

export default FileForUserButtonComponent;
