import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'
import ProductInfoView from 'src/views/staticHomeView/ups/UPSHomeView/ProductInfoView';
import { default as RafProductInfoView } from 'src/views/staticHomeView/raf/ProductInfoView'


const components = {
    UpsProductInfo: ProductInfoView,
    RafProductInfo: RafProductInfoView
};

function ProductInfo({ defaultPagePath }) {
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [htmlPageState, setHtmlPageState] = new useState("");
    const [customPageId, setCustomPageId] = new useState(null);

    useEffect(() => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
        };

        //no default path
        let productInfoPath = (defaultPagePath == null || defaultPagePath == undefined) ? "" : defaultPagePath;

        if (whiteLabelServiceSettings.length > 0) {
            let wbProductInfo = whiteLabelServiceSettings[0].custom.productInfo
            if (wbProductInfo) {
                setCustomPageId(wbProductInfo)
            }
        }

        axios.get(process.env.PUBLIC_URL + productInfoPath, axiosConfig).then(response => {
            setHtmlPageState(response.data)
        });
    }, []);

    function renderProductInfo(customPageId) {
        const ProductInfo = components[customPageId];
        return (<ProductInfo />)
    }

    if (customPageId) {
        return (renderProductInfo(customPageId))
    }
    return (
        <div style={{ padding: '5px' }}
            dangerouslySetInnerHTML={{ __html: htmlPageState }}
        />
    )
}

export default ProductInfo; 