import React, { useState, useEffect } from 'react';
import axios from 'axios'
import {
    useWhiteLabelServiceSettings
} from 'cng-web-lib'

function TermsOfServices() {

    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [htmlPageState, setHtmlPageState] = new useState("");

    useEffect(() => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
        };

        let termsOfServicesPath = ""
        if (whiteLabelServiceSettings.length > 0) {
            let wbTermsOfServicesPath = whiteLabelServiceSettings[0].custom.termsOfServices
            if (wbTermsOfServicesPath != null && wbTermsOfServicesPath != undefined) {
                termsOfServicesPath = wbTermsOfServicesPath;
            }
        }

        axios.get(process.env.PUBLIC_URL + termsOfServicesPath, axiosConfig).then(response => {
            setHtmlPageState(response.data)
        });
    }, []);
    return (
        <>
            <div style={{ padding: '5px' }}
                dangerouslySetInnerHTML={{ __html: htmlPageState }}
            />
        </>
    )
}

export default TermsOfServices; 