import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'
import FAQView from 'src/views/staticHomeView/ups/UPSHomeView/FAQView';

const components = {
    UpsFaq: FAQView
};

function FAQ({ defaultPagePath }) {
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [htmlPageState, setHtmlPageState] = new useState("");
    const [customPageId, setCustomPageId] = new useState(null);

    useEffect(() => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
        };

        //no default path
        let faqPath = (defaultPagePath == null || defaultPagePath == undefined) ? "" : defaultPagePath;

        if (whiteLabelServiceSettings.length > 0) {
            let wbFAQ = whiteLabelServiceSettings[0].custom.faq
            if (wbFAQ) {
                setCustomPageId(wbFAQ)
            }
        }

        axios.get(process.env.PUBLIC_URL + faqPath, axiosConfig).then(response => {
            setHtmlPageState(response.data)
        });
    }, []);

    function renderFaq(customPageId) {
        const FAQ = components[customPageId];
        return (<FAQ />)
    }

    if (customPageId) {
        return (renderFaq(customPageId))
    }
    return (
        <div style={{ padding: '5px' }}
            dangerouslySetInnerHTML={{ __html: htmlPageState }}
        />
    )
}

export default FAQ; 