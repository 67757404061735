import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'
import { default as RBIAboutUsView } from 'src/views/staticHomeView/rbi/AboutUsView';

const components = {
    RbiAboutUs: RBIAboutUsView
};

function AboutUs({ defaultPagePath }) {
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [htmlPageState, setHtmlPageState] = new useState("");
    const [customPageId, setCustomPageId] = new useState(null);

    useEffect(() => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
        };

        let aboutUsPath = (defaultPagePath == null || defaultPagePath == undefined) ? "" : defaultPagePath;

        if (whiteLabelServiceSettings.length > 0) {
            let wbAboutUsPath = whiteLabelServiceSettings[0].custom.aboutUs
            if (wbAboutUsPath != null && wbAboutUsPath != undefined) {
                setCustomPageId(wbAboutUsPath);
            }
        }

        axios.get(process.env.PUBLIC_URL + aboutUsPath, axiosConfig).then(response => {
            setHtmlPageState(response.data)
        });
    }, []);

    function renderAboutUs(customPageId) {
        const AboutUs = components[customPageId];
        return (<AboutUs />)
    }

    if (customPageId) {
        return (renderAboutUs(customPageId))
    }

    return (
        <div style={{ padding: '5px' }} dangerouslySetInnerHTML={{ __html: htmlPageState }} />
    )
}

export default AboutUs; 