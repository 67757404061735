import React, { useState, useEffect } from 'react';
import axios from 'axios'
import {
    useWhiteLabelServiceSettings
} from 'cng-web-lib'

function TermsOfUse() {

    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [htmlPageState, setHtmlPageState] = new useState("");

    useEffect(() => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
        };

        let termsOfUsePath = ""
        if (whiteLabelServiceSettings.length > 0) {
            let wbTermsOfUsePath = whiteLabelServiceSettings[0].custom.termsOfUse
            if (wbTermsOfUsePath != null && wbTermsOfUsePath != undefined) {
                termsOfUsePath = wbTermsOfUsePath;
            }
        }

        axios.get(process.env.PUBLIC_URL + termsOfUsePath, axiosConfig).then(response => {
            setHtmlPageState(response.data)
        });
    }, []);
    return (
        <>
            <div style={{ padding: '5px' }}
                dangerouslySetInnerHTML={{ __html: htmlPageState }}
            />
        </>
    )
}

export default TermsOfUse; 