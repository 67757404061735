import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import FileForUserPage from './FileForUserPage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'

function WrappedFileForUserPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <FileForUserPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export {
  WrappedFileForUserPage as FileForUserPage
}
