import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'
import { default as UPSContactUsView } from 'src/views/staticHomeView/ups/UPSHomeView/ContactUsView'
import { default as RbiContactUsView } from 'src/views/staticHomeView/rbi/ContactUsView'
import { default as RafContactUsView } from 'src/views/staticHomeView/raf/ContactUsView'

const components = {
    UpsContactUs: UPSContactUsView,
    RbiContactUs: RbiContactUsView,
    RafContactUs: RafContactUsView
};

function ContactUs({ defaultPagePath }) {
    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [htmlPageState, setHtmlPageState] = new useState("");
    const [customPageId, setCustomPageId] = new useState(null);

    useEffect(() => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
        };
        let contactUsPath = (defaultPagePath == null || defaultPagePath == undefined) ? "/static/page/gets/ContactUs.html" : defaultPagePath;

        if (whiteLabelServiceSettings.length > 0) {
            let wbContactUs = whiteLabelServiceSettings[0].custom.contactUs
            if (wbContactUs) {
                setCustomPageId(wbContactUs);
            }
        }

        axios.get(process.env.PUBLIC_URL + contactUsPath, axiosConfig).then(response => {
            setHtmlPageState(response.data)
        });
    }, []);

    function renderContactUs(customPageId) {
        const ContactUs = components[customPageId];
        return (<ContactUs />)
    }

    if (customPageId) {
        return (renderContactUs(customPageId))
    }

    return (
        <div style={{ padding: '5px' }} dangerouslySetInnerHTML={{ __html: htmlPageState }} />
    )
}

export default ContactUs; 