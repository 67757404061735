import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useWhiteLabelServiceSettings } from 'cng-web-lib'
import PrivacyPolicyView from 'src/views/staticHomeView/ups/UPSHomeView/PrivacyPolicyView';

const components = {
    UpsPrivacyPolicy: PrivacyPolicyView
};


function PrivacyPolicy({ defaultPagePath }) {

    const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout")
    const [htmlPageState, setHtmlPageState] = new useState("");
    const [customPageId, setCustomPageId] = new useState(null);

    useEffect(() => {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*"
            }
        };

        let privacyPolicyPath = (defaultPagePath == null || defaultPagePath == undefined) ? "" : defaultPagePath;
        if (whiteLabelServiceSettings.length > 0) {
            let wbPrivacyPolicy = whiteLabelServiceSettings[0].custom.privacyPolicy
            if (wbPrivacyPolicy) {
                setCustomPageId(wbPrivacyPolicy);
            }
        }

        axios.get(process.env.PUBLIC_URL + privacyPolicyPath, axiosConfig).then(response => {
            setHtmlPageState(response.data)
        });
    }, []);

    function renderPrivacyPolicy(customPageId) {
        const PrivacyPolicy = components[customPageId];
        return (<PrivacyPolicy viewOnly={true} />)
    }

    if (customPageId) {
        return (renderPrivacyPolicy(customPageId))
    }
    return (
        <div style={{ padding: '5px' }}
            dangerouslySetInnerHTML={{ __html: htmlPageState }}
        />
    )
}

export default PrivacyPolicy; 