import { Card, CardContent } from '@material-ui/core'
import React, { useEffect } from 'react'; 
import { TextField, Button } from '@material-ui/core';
import sweetalert from 'sweetalert'
import { components, useServices, useTranslation } from 'cng-web-lib';
import FileUserUrls from 'src/apiUrls/FileUserApiUrls.js'
import Namespace from 'src/constants/locale/Namespace'
import FileForUserKeys from '../../constants/locale/key/FileForUser'

const{
  card: {
     CngSimpleCardHeader,
  },
   CngGridItem,
}=components

function FileUser () { 

  const { translate } = useTranslation([Namespace.FILE_FOR_USER])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let title = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.TITLE
    )

    let userId = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.USER_ID
    )

    let button = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.BUTTON
    )

    let helpText1 = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.HELP_TEXT_1
    )

    let errorMessage1 = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_1
    )

    let errorMessage2 = translate(
      Namespace.FILE_FOR_USER,
      FileForUserKeys.ERROR_MESSAGE_2
    )
     
    return {
      title,
      userId,
      button,
      helpText1,
      errorMessage1,
      errorMessage2
    }
  }

  const { createRecord } = useServices()
  useEffect(() => {
    document.title = translatedTextsObject.title
    document.getElementById('submitButton').style.textTransform = "capitalize";
  }, []);

  function fileForUser({}) { 
    var userName = document.getElementById('userName').value;
    if(userName !== null && userName.length > 0) {
      document.getElementById('submitButton').className += " Mui-disabled"
      const data = { "userName" : userName }
      createRecord.execute(
        FileUserUrls.SUBMIT,
        {
          ...data,
        },
        (result) => {
          if(result !== undefined && result !== null){
            var token = result.token
            if(token !== undefined){
              localStorage.setItem("userToken", JSON.stringify(token))
              sweetalert({
                title: translatedTextsObject.title,
                text: translatedTextsObject.helpText1 + userName,
                icon: "success",
              }).then(() => {
                window.location.reload()
              })
            } else {
              sweetalert({
                title: translatedTextsObject.title,
                text: translatedTextsObject.errorMessage1,
                icon: "error",
              }).then(() => {
                window.location.reload()
              })
            }
          }
        },
        (error) =>{
          console.log(error)
        }
      )
    } else {
      sweetalert({
        title: translatedTextsObject.title,
        text: translatedTextsObject.errorMessage2,
        icon: "error",
      })
    }
  } 
   
    return ( 
      <Card> 
        <CngSimpleCardHeader title={translatedTextsObject.title} />
        <CardContent>
          <CngGridItem>
            <TextField name="userName" id="userName" variant="outlined" label={translatedTextsObject.userId} inputProps={{maxLength: 50}} required={true} />
          </CngGridItem>
          <CngGridItem style={{marginTop: '50px'}}>
            <Button id="submitButton" 
              variant="contained" 
              style={{justifyContent: 'center'}}
              onClick={fileForUser} 
              color="primary">
              {translatedTextsObject.button}
            </Button>
          </CngGridItem>
        </CardContent>
        </Card>
    ); 
} 

export default FileUser; 